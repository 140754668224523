.inventory-item .actions {
    opacity: 0;
}

.inventory-item .actions:hover {
    opacity: 1;
}

.inventory-item .asset-thumbnail:hover+.actions {
    opacity: 1;
}

.inventory-item .actions {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    /* background-color: rgba(0, 0, 0, 1); */
    width: 100%;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease-in;
}