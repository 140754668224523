.koson-pool-stats-card-layout {
	flex-direction: row;
}

.koson-stats-card .card-header, h4 {
	font-size: large !important;
	font-weight: 700 !important;
}

.koson-stats-card .stat-entry {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid gray;
	padding: 10px;
}

.koson-stats-card .stat-entry:last-child {
	border-bottom: none;
}

.koson-stats-card .actions-wrapper {
	margin-top: 16px;
	display: flex;
	justify-content: center;
}

.koson-stats-card .actions-wrapper .actions-input-menu {
	width: 100%;
	display: flex;
	justify-content: center;
	/* margin: 8px; */
}

.koson-stats-card small {
	cursor: pointer;
}

@media (max-width: 1200px) {
	.koson-stats-card .actions-wrapper {
		flex-direction: column;
		align-items: center;
	}

	.koson-stats-card .actions-wrapper {
		width: 100% !important;
	}

	/* .koson-stats-card .actions-wrapper .actions-input-menu {
		width: 100% !important;
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin: 0px 0px 8px 0px;
	}

	.koson-stats-card .actions-wrapper .actions-input-menu input {
		margin-bottom: 2px;
	}

	.koson-stats-card .actions-wrapper .actions-input-menu button {
		width: 100%;
		margin: 20px 0px 28px 0px !important;
	} */

	.koson-stats-card .desktop-only {
		display: none !important;
	}

	.koson-stats-card .actions-wrapper .mobile-only {
		display: block;
	}

	/* .koson-pool-stats-card-layout {
		flex-direction: column-reverse;
	} */
}