.opening-card {
    border: 1px gray solid;
}

.opening-card .card-body {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}


.opening-card .message {
    display: none;
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: black;
    border-radius: 0.625rem;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.opening-card:hover .message {
    display: flex;
    justify-content: center;
    align-items: center;
}