.filter-box {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    .filter-box {
        justify-content: center !important;
    }

    .quick-filters {
        display: none;
    }
}