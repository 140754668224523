.marketplace-listing-page {
    display: flex;
    flex-direction: column;
}

.marketplace-listing-page .listing-details {
    display: flex;
    flex-direction: row;
}


.marketplace-listing-page .listing-details .card {
    display: flex;
    flex-direction: row;
    width: 60%;
}

.marketplace-listing-page .listing-details .asset-wrapper {
    perspective: 1000px;
    width: 40%;
    padding: 0 6.25rem;
    display: flex;
    flex-direction: column-reverse;
}

.marketplace-listing-page .listing-details .asset-wrapper img {
    width: 90%;
}

.marketplace-listing-page .listing-asset-name {
    text-align: start;
}

.marketplace-listing-page .listing-asset-name a:hover small {
    color: white !important;
    text-decoration: underline !important;
    transition: all 0.3s ease-in-out !important;
}

.marketplace-listing-page .asset-wrapper img {
    transition: transform 0.5s;
    transform: rotateY(-15deg);
    margin-bottom: 30px;
}

.marketplace-listing-page .listing-details .listing-box {
    font-size: larger;
}


.marketplace-listing-page .listing-details .listing-box .chain-logo {
    max-width: 30px;
    margin-left: 10px;
    background-color: black;
    padding: 5px;
    border-radius: 0.625rem;
}

.marketplace-listing-page .listing-details .listing-box .listing-spec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.marketplace-listing-page .listing-details .listing-box .listing-buy-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
}


.marketplace-listing-page .listing-details .listing-box .listing-spec .value {
    font-weight: bolder;
    color: #ffcc99;
}

.border-bottom-sep {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.marketplace-listing-page .listing-details .listing-box .listing-spec.native-price {
    display: none;
}

.marketplace-listing-page .listing-details .listing-box .listing-spec.usd-price {
    display: none;
}

.asset-wrapper .attribute-wrapper {
    display: block;
}

.asset-wrapper .attribute-wrapper {
    display: flex;
    flex-direction: row;
}

.asset-wrapper .attribute-wrapper .attribute {
    background-color: #ffcc99;
    border-radius: 0.625rem;
    color: black;
    padding: 10px;
    text-align: center;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.asset-wrapper .attribute-wrapper .attribute .value {
    width: 100%;
    font-size: large;
    font-weight: bold;
}

.marketplace-listing-page .listing-details .listing-box .listing-spec .value.description {
    width: 50%;
}

@media (max-width: 1440px) {
    .marketplace-listing-page .listing-details {
        flex-direction: column-reverse;
    }

    .marketplace-listing-page .listing-details .card {
        width: 100%;
    }

    .marketplace-listing-page .listing-details .asset-wrapper {
        width: 100%;
        padding: 10px;
        flex-direction: column;
    }

    .marketplace-listing-page .listing-details .asset-wrapper .attribute-wrapper {
        margin-top: 10px;
        flex-direction: column;
    }

    .marketplace-listing-page .listing-details .asset-wrapper img {
        max-width: 80%;
        margin-left: 10%;
    }

    .marketplace-listing-page .listing-details .listing-box .listing-spec.native-price {
        display: block;
    }

    .marketplace-listing-page .listing-details .listing-box .listing-spec.usd-price {
        display: block;
    }

    .marketplace-listing-page .listing-details .listing-box .listing-spec.full-price {
        display: none;
    }

    .marketplace-listing-page .listing-details .listing-box .listing-spec {
        flex-direction: column;
        align-items: start;
    }

    .marketplace-listing-page .listing-details .listing-box .listing-spec .value.description {
        width: 100% !important;
    }
}