.stats-card .card-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 0;
}

.stats-card .card-body {
    padding-top: 0;
    padding-left: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: start;
}

.stats-card .badge {
    color: black;
}