.staked-item .selected {
    border: 1px gold solid;
    transition: all 0.3s ease-in-out;
}

.staked-item .selectable:hover {
    border: 2px gold solid;
    transition: all 0.3s ease-in-out;
    border-radius: .625rem;
    transform: scale(1.05);
}

.staked-item .stake-info {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.625rem;
    padding: 5px 10px;
    font-size: small;
    cursor: pointer;
    white-space: nowrap;
    display: inline-block;
}

.staked-item .stake-info:nth-child(2) {
    top: 70%;
}

.staked-item .stake-info:nth-child(3) {
    top: 85%;
}

.staked-item .qty-picker {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.625rem;
    padding: 5px;
    font-size: small;
    cursor: pointer;
}