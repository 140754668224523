.select-chain-card {
    cursor: pointer;
}

.select-chain-card img {
    max-width: 20%;
    margin: auto;
    width: auto;
    margin-bottom: 25px;
}

.select-chain-card span {
    font-size: larger;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.select-chain-card:hover {
    background-color: #3a3a4a !important;
    transition: all 0.3s ease-in-out;
}

.custom-btn {
    border: none;
    background-color: #996633;
    color: #fafafa;
}

.custom-btn:hover {
    background-color: #7a5529;
}

.custom-btn:active {
    background-color: #663300;
}

.custom-btn:focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 102, 51, 0.5);
}