.koson-staking-pool-row {
    display: flex;
    align-items: center;
    border: 1px rgba(255, 255, 255, 0.1) solid;
    background-color: rgba(153, 102, 51, .1);
    border-color: rgba(153, 102, 51, .1);
    border-radius: 1.625rem;
    padding: 16px;
    margin: 10px;
}

.koson-staking-pool-row .pool-title {
    font-size: xx-large;
}

.koson-staking-pool-row .pool-description {
    font-size: larger;
    font-weight: 400;
}

.btn-circle {
    border-radius: 50% !important;
    padding: 1.5rem !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 1.5 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold !important;
    font-size: larger !important;
}


.koson-staking-pool-row .btn-action {
    display: none;
}

.koson-staking-pool-row .pool-actions {
    display: flex;
    justify-content: end;
}

@media (max-width: 767px) {
    .koson-staking-pool-row {
        flex-direction: column;
        gap: 16px;
    }

    .koson-staking-pool-row .pool-actions {
        flex-direction: column;
        gap: 16px;
    }

    .btn-circle {
        display: none;
    }

    .koson-staking-pool-row .btn-action {
        display: flex;
        justify-content: center;
    }
}