.marketplace-listing {
    border: none;
    background-color: rgba(17, 17, 20, 0.4) !important;
}

.marketplace-listing .card-body {
    position: relative;
    padding-bottom: 3.5rem;
}

.marketplace-listing .expire-deadline, .marketplace-listing .quick-buy {
    position: absolute;
    top: 0;
    width: 100%;
    left: 50%;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}

.marketplace-listing .expire-deadline {
    transform: translate(-50%, 25%);

}

.marketplace-listing .quick-buy {
    transform: translate(-50%, 5%);

}