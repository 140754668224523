.main-content {
    flex-grow: 1;
    transition: all 0.3s;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 85px);
    background-color: #1e1e25;
    padding: 20px;
    color: white;
}

.logo-box {
    padding: 10px;
    display: flex;
    align-items: center;
}

.logo-min {
    margin-left: 22.5px;
}

.collapse-btn {
    color: white;
    margin-left: 40px;
    margin-right: 30px;
}

@media (max-width: 767px) {
    .main-content {
        padding-left: 15px;
        transition: all 0.3s;
    }

    .main-content.active {
        padding-left: 255px;
    }

    .logo-box img {
        display: none;
    }
}

.bg-main {
    background-color: #2d2d37 !important;
}