.chest-content {
    list-style-type: circle !important;
    list-style-position: inside !important;
}

.important-text {
    color: #E9D2AF;
}

.btn-carousel {
    color: gray;
}

.btn-carousel:hover {
    color: white;
}

.chest-drop.legendary {
    border: 2px solid rgb(214, 202, 150) !important;
    box-shadow: 0 8px 16px 0 rgba(214, 202, 150, 0.2), 0 12px 30px 0 rgba(214, 202, 150, 0.19) !important;
}

.chest-drop.continental {
    border: 1px solid #966F33 !important;
}

.chest-drop.steepe {
    border: 1px solid #CD7F32 !important;
}

.chest-drop.panonic {
    border: 1px solid #C0C0C0 !important;
}

.chest-drop.pontic {
    border: 1px solid #FFD700 !important;
}