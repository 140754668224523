.sidebar {
  background-color: #1e1e25;
  border-right: 1px solid rgba(213, 223, 231, 0.1);
  transition: width 0.3s;
  height: 100vh;
  width: 270px;
  overflow: hidden;
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar.collapsed {
  width: 85px;
  padding: 15px;
}

.sidebar .logo {
  width: 100%;
  max-width: 180px;
  height: auto;
}

.sidebar .menu-item {
  width: calc(100% - 10px);
  padding: 0.935rem;
  border-radius: 0.625rem;
  opacity: 0.6;
  transition: 0.3s;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
}

.sidebar.collapsed .menu-item {
  width: 100%;
  display: flex;
  justify-items: center;
}

.sidebar.collapsed .hide-on-collapsed {
  display: none;
}

.sidebar .menu-item .link-icon {
  margin-right: 20px;
  font-size: 1.3rem !important;
}

.sidebar.collapsed .link-icon {
  margin: auto;
}

.sidebar.collapsed span {
  display: none !important;
}

.sidebar .menu-item:hover {
  opacity: 1;
}

.sidebar .menu-item.active {
  background-color: #996633;
  opacity: 1;
}

.sidebar .menu-item.sub {
  margin-left: 20px;
  max-width: calc(100% - 30px);
}

.connect-button {
  position: absolute;
  width: 100%;
  bottom: 0;
  /* bottom: 10vh;
  margin-bottom: 10px; */
  /* ensure button takes full width */
}

.exchange-price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15%;
  margin-top: 10%;
}

.sidebar .exchange-price .exchange-logo {
  display: inline;
  width: auto;
  height: auto;
  max-width: 20px;
  max-height: 20px;
  margin-right: 10px;
}

.sidebar.collapsed .exchange-price .exchange-logo {
  display: none;
}

.exchange-price .buy-koson-link {
  text-decoration: none;
  color: gray;
  font-size: 0.875rem;
}

@media (max-width: 1024px) {
  .connect-button {
    bottom: 10vh;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .sidebar.collapsed {
    position: fixed;
    z-index: 9999;
    left: -100%;
    width: 100%;
  }

  .sidebar {
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 9999;
    transition: left 0.3s ease-in-out;
  }

  .sidebar.collapsed span, .sub {
    display: inherit !important;
  }

  .sidebar.collapsed .hide-on-collapsed {
    display: inherit;
  }

  .exchange-price {
    display: none;
  }
}