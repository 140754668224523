.timeline {
    list-style-type: none;
}

.timeline li {
    width: 100%;
    color: white;
}

.timeline-badge {
    padding: 10px 20px;
}

.timeline-badge.success {
    background-color: rgba(25, 135, 84, 0.3) !important;
}

.timeline-badge.info {
    background-color: rgba(13, 202, 240, 0.3) !important;
}

.timeline-badge.primary {
    background-color: rgb(153, 102, 51, 0.3) !important;
}

.timeline-badge.danger {
    background-color: rgba(220, 53, 69, 0.3) !important;
}

.timeline-badge.warning {
    background-color: rgba(255, 193, 7, 0.3) !important;
}

.timeline-badge.dark {
    background-color: rgba(33, 37, 41, 0.6) !important;
}




.timeline-badge.success:hover {
    background-color: rgba(25, 135, 84, 0.5) !important;
}

.timeline-badge.info:hover {
    background-color: rgba(13, 202, 240, 0.5) !important;
}

.timeline-badge.primary:hover {
    background-color: rgb(153, 102, 51, 0.5) !important;
}

.timeline-badge.danger:hover {
    background-color: rgba(220, 53, 69, 0.5) !important;
}

.timeline-badge.warning:hover {
    background-color: rgba(255, 193, 7, 0.5) !important;
}

.timeline-badge.dark:hover {
    background-color: rgba(33, 37, 41, 0.9) !important;
}